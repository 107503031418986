var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "appeals.create",
              expression: "'appeals.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "appeals",
          order_column: "created_at",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "protocol_number",
                    attrs: { "sort-key": "protocol_number" },
                  },
                  [_vm._v(_vm._s(_vm.$t("appeal.protocol")))]
                ),
                _c(
                  "vs-th",
                  { key: "model_name", attrs: { "sort-key": "model_name" } },
                  [_vm._v(_vm._s(_vm.$t("appeal.questionnaire")))]
                ),
                _c(
                  "vs-th",
                  { key: "created_at", attrs: { "sort-key": "created_at" } },
                  [_vm._v(_vm._s(_vm.$t("appeal.created_at")))]
                ),
                _c(
                  "vs-th",
                  { key: "status", attrs: { "sort-key": "status" } },
                  [_vm._v(_vm._s(_vm.$t("appeal.status")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "protocol_number", attrs: { width: "20%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.protocol_number))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "model_name", attrs: { width: "30%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.model_name))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "created_at", attrs: { width: "10%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.created_dt_fmt))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "status", attrs: { width: "10%", so: "" } },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(tr.handling_status.label)),
                        ]),
                      ]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex flex-row justify-center gap-1" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "appeal_handlings.show",
                                expression: "'appeal_handlings.show'",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: "Detalhar Recurso",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{content: 'Detalhar Recurso',delay: { show: 1000, hide: 100 }}",
                                modifiers: { top: true },
                              },
                            ],
                            attrs: {
                              icon: "EyeIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.detail(props.data[indextr].id)
                              },
                            },
                          }),
                          props.data[indextr].handling_status.key == "OP" ||
                          props.data[indextr].handling_status.key == "PE"
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "appeals.edit",
                                    expression: "'appeals.edit'",
                                  },
                                ],
                                attrs: {
                                  icon: "EditIcon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                                },
                                on: {
                                  click: function ($event) {
                                    return props.edit(props.data[indextr].id)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "appeals.delete",
                                expression: "'appeals.delete'",
                              },
                            ],
                            attrs: {
                              icon: "Trash2Icon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                            },
                            on: {
                              click: function ($event) {
                                return props.destroy(props.data[indextr])
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }